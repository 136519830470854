import Constants from '../data/constants';
import { isThemeDark } from './utils';

const THEMES = [Constants.DARK_THEME, Constants.LIGHT_THEME];
type Theme = typeof THEMES[number];

export const initialTheme = (): Theme => {
  if (window && window.matchMedia("(prefers-color-scheme: dark)").matches) {
    return Constants.DARK_THEME;
  }

  return Constants.LIGHT_THEME;
}

export const applyTheme = (theme: Theme) => {
  const htmlQuerySelector = document.querySelector('html')

  const themeToBeRemoved: Theme = isThemeDark(theme) ? Constants.LIGHT_THEME : Constants.DARK_THEME;
  if (htmlQuerySelector) {
    htmlQuerySelector.classList.remove(themeToBeRemoved);
    htmlQuerySelector.classList.add(theme);
  }
}
