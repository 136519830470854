interface Props {
  examResultYear: string
}

function Header(props: Props) {
  let { examResultYear } = props;

  return (
    <>
      <header className="sticky bg-white top-2 md:top-4 dark:bg-gray-800">
        <h1>Simulateur bac {examResultYear}</h1>
      </header>
      <p>Le simulateur du bac vous permet de calculer votre mention au bac, en fonction de vos notes.</p>
    </>
  )
}

export default Header;
