import Constants from '../data/constants';
import ThemeToggle from './ThemeToggle';
import ReinitializeTestsValues from './ReinitializeTestsValues';

const THEMES = [Constants.DARK_THEME, Constants.LIGHT_THEME];
type Theme = typeof THEMES[number];

interface Props {
  updateTheme: (theme: Theme) => void;
  theme: Theme;
  setTestsValues: (testsValues: {}) => void;
}

function Options (props: Props) {
  let { updateTheme, theme, setTestsValues } = props;

  return (
    <div className="w-3/4 mx-auto mt-12 md:w-2/3 grid grid-cols-1 md:grid-cols-2 gap-2">
      <div>
        <ThemeToggle updateTheme={updateTheme} theme={theme} />
      </div>
      <div>
         <ReinitializeTestsValues setTestsValues={setTestsValues} />
      </div>
    </div>
  )
}

export default Options;
