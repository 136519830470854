const Constants = Object.freeze({
    GRADE_MINIMUM: 0,
    GRADE_MAXIMUM: 20,
    DECIMALS_NUMBER: 2,
    NOT_APPLICABLE: 'na',
    LIGHT_THEME: 'light',
    DARK_THEME: 'dark'
});

export default Constants;
