import Constants from '../data/constants';

export const testsInitialValues: any =
{
  "version": "3",
  "trackIndex": "0",
  "tracks":
  [
    {
      "trackName": {
        "complete": "Voies générales",
        "shortened": "Générale"
      },
      "showHiddenOptions": false,
      "testsTypes": [
        {
          "testsTypeName": "Contrôle continu",
          "tests": [
            {
              "testName": "Enseignement scientifique",
              "testResults": [
                {
                  "grade": '',
                  "coefficient": 3
                },
                {
                  "grade": '',
                  "coefficient": 3
                }
              ],
            },
            {
              "testName": "Histoire-géographie",
              "testResults": [
                {
                  "grade": '',
                  "coefficient": 3
                },
                {
                  "grade": '',
                  "coefficient": 3
                }
              ],
            },
            {
              "testName": "Langue vivante A",
              "testResults": [
                {
                  "grade": '',
                  "coefficient": 3
                },
                {
                  "grade": '',
                  "coefficient": 3
                }
              ],
            },
            {
              "testName": "Langue vivante B",
              "testResults": [
                {
                  "grade": '',
                  "coefficient": 3
                },
                {
                  "grade": '',
                  "coefficient": 3
                }
              ],
            },
            {
              "testName": "Enseignement de spécialité 1",
              "testResults": [
                {
                  "grade": '',
                  "coefficient": 8
                },
                {
                  "grade": Constants.NOT_APPLICABLE,
                  "coefficient": 0
                }
              ],
            },
            {
              "testName": "EPS",
              "testResults": [
                {
                  "grade": Constants.NOT_APPLICABLE,
                  "coefficient": 0
                },
                {
                  "grade": '',
                  "coefficient": 6
                }
              ],
            },
            {
              "testName": "Enseignement moral et civique",
              "testResults": [
                {
                  "grade": '',
                  "coefficient": 1
                },
                {
                  "grade": '',
                  "coefficient": 1
                }
              ],
            },
            {
              "testName": "Enseignement optionnel 1",
              "testResults": [
                {
                  "grade": '',
                  "coefficient": 2
                },
                {
                  "grade": '',
                  "coefficient": 2
                },
              ],
              "optional": true
            },
            {
              "testName": "Enseignement optionnel 2",
              "testResults": [
                {
                  "grade": Constants.NOT_APPLICABLE,
                  "coefficient": 0
                },
                {
                  "grade": '',
                  "coefficient": 2
                }
              ],
              "optional": true
            },
            {
              "testName": " Langues et cultures de l'Antiquité 1",
              "testResults": [
                {
                  "grade": '',
                  "coefficient": 2
                },
                {
                  "grade": '',
                  "coefficient": 2
                }
              ],
              "optional": true
            },
            {
              "testName": " Langues et cultures de l'Antiquité 2",
              "testResults": [
                {
                  "grade": '',
                  "coefficient": 2
                },
                {
                  "grade": '',
                  "coefficient": 2
                }
              ],
              "optional": true
            },
          ]
        },
        {
          "testsTypeName": "Épreuves finales",
          "tests": [
            { "testName": "Français - écrit",
              "testResults": [
                {
                  "grade": '',
                  "coefficient": 5
                },
                {
                  "grade": Constants.NOT_APPLICABLE,
                  "coefficient": 0
                }
              ],
            },
            {
              "testName": "Français - oral",
              "testResults": [
                {
                  "grade": '',
                  "coefficient": 5
                },
                {
                  "grade": Constants.NOT_APPLICABLE,
                  "coefficient": 0
                }
              ],
            },
            {
              "testName": "Philosophie",
              "testResults": [
                { "grade": Constants.NOT_APPLICABLE,
                  "coefficient": 0
                },
                {
                  "grade": '',
                  "coefficient": 8
                }
              ],
            },
            {
              "testName": "Grand oral",
              "testResults": [
                { "grade": Constants.NOT_APPLICABLE,
                  "coefficient": 0
                },
                {
                  "grade": '',
                  "coefficient": 10
                }
              ],
            },
            {
              "testName": "Enseignement de spécialité 2",
              "testResults": [
                { "grade": Constants.NOT_APPLICABLE,
                  "coefficient": 0
                },
                {
                  "grade": '',
                  "coefficient": 16
                }
              ],
            },
            {
              "testName": "Enseignement de spécialité 3",
              "testResults": [
                { "grade": Constants.NOT_APPLICABLE,
                  "coefficient": 0
                },
                {
                  "grade": '',
                  "coefficient": 16
                }
              ],
            },
          ]
        }
      ],
    },
    {
      "trackName": {
        "complete": "Voies technologiques",
        "shortened": "Technologique"
      },
      "showHiddenOptions": true,
      "testsTypes": [
        {
          "testsTypeName": "Contrôle continu",
          "tests": [
            {
              "testName": "Mathématiques",
              "testResults": [
                { "grade": '',
                  "coefficient": 3
                },
                {
                  "grade": '',
                  "coefficient": 3
                }
              ],
            },
            {
              "testName": "Histoire-géographie",
              "testResults": [
                { "grade": '',
                  "coefficient": 3
                },
                {
                  "grade": '',
                  "coefficient": 3
                }
              ],
            },
            {
              "testName": "Langue vivante A",
              "testResults": [
                { "grade": '',
                  "coefficient": 3
                },
                {
                  "grade": '',
                  "coefficient": 3
                }
              ],
            },
            {
              "testName": "Langue vivante B",
              "testResults": [
                { "grade": '',
                  "coefficient": 3
                },
                {
                  "grade": '',
                  "coefficient": 3
                }
              ],
            },
            {
              "testName": "Enseignement de spécialité 1",
              "testResults": [
                {
                  "grade": '',
                  "coefficient": 8
                },
                {
                  "grade": Constants.NOT_APPLICABLE,
                  "coefficient": 0
                }
              ],
            },
            {
              "testName": "EPS",
              "testResults": [
                {
                  "grade": Constants.NOT_APPLICABLE,
                  "coefficient": 0
                },
                {
                  "grade": '',
                  "coefficient": 6
                }
              ],
            },
            {
              "testName": "Enseignement moral et civique",
              "testResults": [
                {
                  "grade": '',
                  "coefficient": 1
                },
                {
                  "grade": '',
                  "coefficient": 1
                }
              ],
            },
            {
              "testName": "Enseignement optionnel 1",
              "testResults": [
                {
                  "grade": '',
                  "coefficient": 2
                },
                {
                  "grade": '',
                  "coefficient": 2
                },
              ],
              "optional": true
            },
            {
              "testName": "Enseignement optionnel 2",
              "testResults": [
                {
                  "grade": '',
                  "coefficient": 2
                },
                {
                  "grade": '',
                  "coefficient": 2
                }
              ],
              "optional": true
            },
            {
              "testName": " Langues et cultures de l'Antiquité 1",
              "testResults": [
                {
                  "grade": '',
                  "coefficient": 2
                },
                {
                  "grade": '',
                  "coefficient": 2
                }
              ],
              "optional": true
            },
            {
              "testName": " Langues et cultures de l'Antiquité 2",
              "testResults": [
                {
                  "grade": '',
                  "coefficient": 2
                },
                {
                  "grade": '',
                  "coefficient": 2
                }
              ],
              "optional": true
            },
          ]
        },
        {
          "testsTypeName": "Épreuves finales",
          "tests": [
            {
              "testName": "Français - écrit",
              "testResults": [
                {
                  "grade": '',
                  "coefficient": 5
                },
                {
                  "grade": Constants.NOT_APPLICABLE,
                  "coefficient": 0
                }
              ],
            },
            {
              "testName": "Français - oral",
              "testResults": [
                {
                  "grade": '',
                  "coefficient": 5
                },
                {
                  "grade": Constants.NOT_APPLICABLE,
                  "coefficient": 0
                }
              ],
            },
            {
              "testName": "Philosophie",
              "testResults": [
                {
                  "grade": Constants.NOT_APPLICABLE,
                  "coefficient": 0
                },
                {
                  "grade": '',
                  "coefficient": 4
                }
              ],
            },
            {
              "testName": "Grand oral",
              "testResults": [
                {
                  "grade": Constants.NOT_APPLICABLE,
                  "coefficient": 0
                },
                {
                  "grade": '',
                  "coefficient": 14
                }
              ],
            },
            {
              "testName": "Enseignement de spécialité 2",
              "testResults": [
                {
                  "grade": Constants.NOT_APPLICABLE,
                  "coefficient": 0
                },
                {
                  "grade": '',
                  "coefficient": 16
                }
              ],
            },
            {
              "testName": "Enseignement de spécialité 3",
              "testResults": [
                {
                  "grade": Constants.NOT_APPLICABLE,
                  "coefficient": 0
                },
                {
                  "grade": '',
                  "coefficient": 16
                }
              ],
            },
          ]
        }
      ]
    }
  ]
}
