interface TrackName {
  complete: string
  shortened: string
}

interface Track {
  trackName: TrackName;
  testsTypes: {}[];
}

interface Props {
  currentTrackIndex: number
  updateTrackIndex: (trackIndex: number) => void
  track: Track
  trackIndex: number
}

function ButtonTrack(props: Props) {
  let {  currentTrackIndex, updateTrackIndex, track, trackIndex } = props;

  return (
   <div className="text-center button button-blue"
     onClick={() => updateTrackIndex(trackIndex)}
   >
      <span className="md:hidden">{track["trackName"]["shortened"]}</span>
      <span className="hidden pl-2 md:inline-block md:pl-4">
        {track["trackName"]["complete"]}
      </span>
      <span className={
        currentTrackIndex === trackIndex ?
        'icon-check ml-1 pb-0.5 align-middle visible':
        'icon-check ml-1 pb-0.5 align-middle invisible'
      }
      ></span>
    </div>
  )
}

export default ButtonTrack;
