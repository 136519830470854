import React from 'react';
import ButtonOption from './ButtonOption';
import Constants from '../data/constants';
import { isThemeDark } from '../lib/utils';

const THEMES = [Constants.DARK_THEME, Constants.LIGHT_THEME];
type Theme = typeof THEMES[number];

interface Props {
  theme: Theme;
  updateTheme: (theme: Theme) => void;
}

const ThemeToggle = (props: Props) => {
  const { theme, updateTheme } = props;
  const action = () => updateTheme(isThemeDark(theme) ? Constants.LIGHT_THEME : Constants.DARK_THEME)
  const text = () => {
    return (
      <>
        Thème {isThemeDark(theme) ?
            ['clair', <span key="clair" className="icon-sun-solid ml-2 align-middle pb-0.5"></span>] :
            ['sombre', <span key="sombre" className="icon-moon-solid ml-2 align-middle pb-0.5"></span>]}
      </>
    )
  }

  return (
    <ButtonOption action={action} text={text} />
  )
}

export default ThemeToggle;
