import { testsInitialValues } from '../data/testsInitialValues';
import ButtonOption from './ButtonOption';

interface Props {
  setTestsValues: (testValues: {}) => void;
}

function ReinitializeTestsValues(props: Props) {
  let { setTestsValues } = props;
  const action = () => setTestsValues(testsInitialValues)
  const text = () => {
    return (
      <>
        Effacer les données <span className="icon-redo-solid ml-2 align-middle pb-0.5"></span>
      </>
    )
  }

  return (
    <ButtonOption action={action} text={text} />
  )
}

export default ReinitializeTestsValues;
