export const merits = [
  {
    minGrade: 0,
    maxGrade: 8,
    name: 'Recalé'
  },
  {
    minGrade: 8,
    maxGrade: 10,
    name: 'Rattrapage',
  },
  {
    minGrade: 10,
    maxGrade: 12,
    name: 'Admis',
  },
  {
    minGrade: 12,
    maxGrade: 14,
    name: 'Assez bien',
  },
  {
    minGrade: 14,
    maxGrade: 16,
    name: 'Bien',
  },
  {
    minGrade: 16,
    maxGrade: 40,
    name: 'Très bien',
  }
]
