import Constants from'../../data/constants';

interface Props {
  typeIndex: number;
  testIndex: number;
  resultIndex: number;
  isLastResultIndex: boolean;
  isHiddeable: boolean;
  numberOfResults: number;
  resultGrade: string,
  updateGrade: (event: React.ChangeEvent<HTMLInputElement>, typeIndex: number, testIndex: number, resultIndex: number) => void
}

function TestResultDiv(props: Props) {
  let { typeIndex, testIndex, resultIndex, isLastResultIndex, isHiddeable, numberOfResults, resultGrade, updateGrade } = props
  let backgroundClasses: string;
  if (isLastResultIndex) {
    if (isHiddeable) {
      backgroundClasses = 'bg-green-100 dark:bg-green-500';
    } else {
      backgroundClasses = 'bg-green-200 dark:bg-green-600';
    }
  } else {
    if (isHiddeable) {
      backgroundClasses = 'bg-yellow-100 dark:bg-yellow-500';
    } else {
      backgroundClasses = 'bg-yellow-200 dark:bg-yellow-600';
    }
  }

  let widthClasses = numberOfResults === 2 ? { div: 'w-1/2 md:w-36', input: 'w-2/3 block mx-auto' } : { div: 'w-1/3 md:w-24',  input: 'w-full' }

  return (
    <div className={`p-2  ${backgroundClasses} ${widthClasses['div']}`}>
      { (resultGrade === Constants.NOT_APPLICABLE) ? '':
        <input type="number"
               step="0.10"
               min={Constants.GRADE_MINIMUM}
               max={Constants.GRADE_MAXIMUM}
               value={resultGrade}
               onChange={(e) => updateGrade(e, typeIndex, testIndex, resultIndex)}
               className={widthClasses['input']}
        />
      }
    </div>
  )
}

export default TestResultDiv;
