import TestsTypeDiv from './TestsTypeDiv';

interface TestResult {
  grade: string;
  coefficient: number;
}

interface Test {
  testName: string;
  testResults: TestResult[];
  optional: boolean;
  toolTip: string;
}

interface TestsType {
  testsTypeName: string
  tests: Test[]
}

interface TrackTestsValues {
  testsTypes: TestsType[]
}

interface Props {
  trackTestsValues: TrackTestsValues
  updateGrade: (event:  React.ChangeEvent<HTMLInputElement>, typeIndex: number, testIndex: number, resultIndex: number) => void;
  toggleHiddenOptions: () => void;
  showHiddenOptions: boolean;
}


function TestsForm(props: Props) {
  let { trackTestsValues, updateGrade, toggleHiddenOptions, showHiddenOptions } = props;

  return (
    <div className="mt-4">
      <form className="standard">
      {
        trackTestsValues["testsTypes"]
          .map((testsType, typeIndex) => {
            return (
              <TestsTypeDiv testsType={testsType}
                            typeIndex={typeIndex}
                            updateGrade={updateGrade}
                            toggleHiddenOptions={toggleHiddenOptions}
                            showHiddenOptions={showHiddenOptions}
                            key={testsType.testsTypeName}
              />
            )
          })
      }
      </form>
    </div>
  )
}

export default TestsForm;
