interface Props {
 action: () => void
  text: () => JSX.Element
}

function ButtonOption(props: Props) {
  const { action, text } = props;

  return (
    <div onClick={action} className="text-center button button-transparent">
      {text()}
    </div>
  )
}

export default ButtonOption;
