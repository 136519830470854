import TestDiv from './TestDiv';
import Constants from '../../data/constants';

interface TestResult {
  grade: string;
  coefficient: number;
}

interface Test{
  testName: string;
  testResults: TestResult[];
  optional: boolean;
  toolTip: string;
}

interface TestsType {
  testsTypeName: string
  tests: Test[]
}

interface Props {
  testsType: TestsType;
  typeIndex: number;
  updateGrade: (event: React.ChangeEvent<HTMLInputElement>, typeIndex: number, testIndex: number, resultIndex: number) => void;
  toggleHiddenOptions: () => void;
  showHiddenOptions: boolean;
}

function TestsTypeDiv(props: Props) {
  let { testsType, typeIndex, updateGrade, toggleHiddenOptions, showHiddenOptions } = props
  return (
    <div>
      <h2 className="mt-2">{testsType.testsTypeName}</h2>
      {testsType["tests"].map((test, testIndex) => {
        return (
          <TestDiv test={test}
                typeIndex={typeIndex}
                testIndex={testIndex}
                updateGrade={updateGrade}
                showHiddenOptions={showHiddenOptions}
                key={test.testName}
          />
        )
      })}
      {
        testsType["tests"].some((x) => x.optional === true && x.testResults.every((y) => y.grade === '' || y.grade === Constants.NOT_APPLICABLE)) &&
          (<div className="text-right">
                <label className="text-sm italic font-thin"
                  htmlFor="afficher-options"
                >
                  Afficher les options disponibles:
                </label>
                <input id="afficher-options"
                       type="checkbox"
                       className="ml-2 align-middle"
                       onChange={() => toggleHiddenOptions()}
                       checked={showHiddenOptions}
                />
           </div>)
      }
    </div>
  )
}

export default TestsTypeDiv;
