import Constants from '../data/constants';

interface Merit {
  minGrade: number;
  maxGrade: number;
  name: string;
}

export const isMeritForAverage = (average: number | '-') =>
  (merit: Merit): boolean  => {
    if (average === '-') {
      return false;
    }

    return merit.minGrade <= average && merit.maxGrade > average;
  }

interface TestResult {
  grade: string;
  coefficient: number;
}

interface Test {
  testName: string;
  testResults: TestResult[];
  optional: boolean;
}

interface TestsType {
  testsTypeName: string;
  tests: Test[]
}

interface TrackName {
  complete: string;
  shortened: string;
}

interface TrackTestsValues {
  trackName: TrackName;
  testsTypes: TestsType[];
}

interface TrackTestsValuesReducerWrapper {
  (optionTaken: boolean): TrackTestsValuesReducer;
}

interface TrackTestsValuesReducer {
  (accumulator: number, currentValue: TestResult, currentValueIndex: number, array: TestResult[]): number;
}

export function reduceTestsValues(trackTestsValues: TrackTestsValues, callback: TrackTestsValuesReducerWrapper) {
  return trackTestsValues["testsTypes"]
    .reduce((typesAccumulator: number, currentType: TestsType) => {
      return typesAccumulator + currentType["tests"].reduce((testsAccumulator: number, currentTest: Test) => {
        let optionTaken = !currentTest.optional || currentTest.testResults.some((x) => x.grade !== '' && x.grade !== Constants.NOT_APPLICABLE)

        return testsAccumulator + currentTest["testResults"].reduce(callback(optionTaken), 0)
      }, 0)
    }, 0);
}

export function maximumGradesNumber(trackTestsValues: TrackTestsValues) {
  const countApplicableResults = (optionTaken: boolean) => (resultsAccumulator: number, currentResult: TestResult) => {
    if (optionTaken === false) {
      return 0;
    }

    if (currentResult.grade === Constants.NOT_APPLICABLE) {
      return resultsAccumulator;
    }
    return resultsAccumulator + 1;
  }


  return reduceTestsValues(trackTestsValues, countApplicableResults);
}

export function gradesNumber(trackTestsValues: TrackTestsValues) {
  const countEnteredResults = (optionTaken: boolean) => (resultsAccumulator: number, currentResult: TestResult) => {
    if (optionTaken === false) {
      return 0;
    }

    if (currentResult.grade === Constants.NOT_APPLICABLE || currentResult.grade === '') {
      return resultsAccumulator;
    }
    return resultsAccumulator + 1;
  }

  return reduceTestsValues(trackTestsValues, countEnteredResults);
}

export function coefficientsTotal(trackTestsValues: TrackTestsValues) {
  const sumCoefficientForEnteredGrades = (optionTaken: boolean) =>
    (resultsAccumulator: number, currentResult: TestResult, currentResultIndex: number, currentResults: TestResult[]) => {
      if (optionTaken === false) {
        return 0;
      }
      if (currentResult.grade === Constants.NOT_APPLICABLE || currentResult.grade === '') {
        return resultsAccumulator;
      }
      return resultsAccumulator + currentResult.coefficient;
  }

  return reduceTestsValues(trackTestsValues, sumCoefficientForEnteredGrades);
}

export function gradesTotal(trackTestsValues: TrackTestsValues) {
  const sumEnteredGrades = (optionTaken: boolean) =>
    (resultsAccumulator: number, currentResult: TestResult, currentResultIndex: number, currentResults: TestResult[]) => {
      if (optionTaken === false) {
        return 0;
      }
      if (currentResult.grade === Constants.NOT_APPLICABLE || currentResult.grade === '') {
        return resultsAccumulator;
      }

      return resultsAccumulator + Number(currentResult.grade)*currentResult.coefficient;
  }

  return reduceTestsValues(trackTestsValues, sumEnteredGrades);
}
