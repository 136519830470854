interface Props {
  resultValue: any;
  resultLabel: string;
}

function OverallResultDiv(props: Props) {
  let { resultValue, resultLabel } = props;
  return (
    <div className="p-4 text-center border border-gray-500">
      <span className="inline-block mb-2 text-2xl break-all md:text-4xl">
        {resultValue}
      </span><br />
      {resultLabel}
    </div>
  )
}

export default OverallResultDiv;
