import ButtonTrack from './ButtonTrack';

interface TrackName {
  complete: string;
  shortened: string;
}

interface Track {
  trackName: TrackName;
  testsTypes: {}[];
}

interface TestsValues {
  tracks: Track[];
}

interface Props {
  testsValues: TestsValues;
  currentTrackIndex: number;
  updateTrackIndex: (trackIndex: number) => void;
}

function TrackChoice(props: Props) {
  let { testsValues, currentTrackIndex, updateTrackIndex } = props;
  return (
    <div className="mt-4">
      <span className="font-bold">Sélectionnez votre voie:</span>
      <div className="block mt-1 md:inline-block md:ml-2 md:mt-0">
        <div className="grid grid-cols-2 gap-2">
          {
            testsValues["tracks"].map((track, trackIndex) => {
              return(
                <ButtonTrack
                  currentTrackIndex={currentTrackIndex}
                  updateTrackIndex={updateTrackIndex}
                  track={track}
                  trackIndex={trackIndex}
                  key={trackIndex}
                />
              );
            })
          }
        </div>
      </div>
    </div>
  )
}

export default TrackChoice;
