import { useEffect } from 'react';
import Constants from './data/constants';
import useLocalStorage from './lib/useLocalStorage';
import Header from './components/Header';
import TrackChoice from './components/TrackChoice';
import OverallResults from './components/OverallResults';
import TestsForm from './components/form/TestsForm';
import Options from './components/Options';
import Footer from './components/Footer';
import { applyTheme } from './lib/theme';
import { makeInputGradeOrEmptyString, examResultYear } from './lib/utils';
import { testsInitialValues } from './data/testsInitialValues';
import { themeInitialValue } from './data/themeInitialValue';

function App() {
  const [testsValues, setTestsValues] = useLocalStorage('testsValues', testsInitialValues);
  const [themeValue, setThemeValue] = useLocalStorage('themeValue', themeInitialValue);

  useEffect(() => { applyTheme(themeValue["theme"]) }, [themeValue])
  useEffect(() => { document.title += ` ${examResultYear(new Date())}` }, [])

  function updateGrade(e: React.ChangeEvent<HTMLInputElement>, typeIndex: number, testIndex: number, resultIndex: number) {
    const newGrade = makeInputGradeOrEmptyString(e.target.value);
    const newTestsValues = JSON.parse(JSON.stringify(testsValues))

    newTestsValues["tracks"][newTestsValues["trackIndex"]]["testsTypes"][typeIndex]["tests"][testIndex]["testResults"][resultIndex]["grade"] = newGrade;
    setTestsValues(newTestsValues)
  }

  const THEMES = [Constants.DARK_THEME, Constants.LIGHT_THEME];
  type Theme = typeof THEMES[number];

  function updateTheme(theme: Theme) {
    const newThemeValue = JSON.parse(JSON.stringify(themeValue))

    newThemeValue["theme"] = theme;
    setThemeValue(newThemeValue)
  }

  function updateTrackIndex(newTrackIndex: number) {
    const newTestsValues = JSON.parse(JSON.stringify(testsValues));

    newTestsValues["trackIndex"] = String(newTrackIndex);
    setTestsValues(newTestsValues)
  }

  function toggleHiddenOptions() {
    const newTestsValues = JSON.parse(JSON.stringify(testsValues));
    const newShowHiddenOptions = !newTestsValues["tracks"][newTestsValues["trackIndex"]]["showHiddenOptions"];

    newTestsValues["tracks"][newTestsValues["trackIndex"]]["showHiddenOptions"] = newShowHiddenOptions;
    setTestsValues(newTestsValues)
  }

  function getTrackIndex() {
    return Number(testsValues["trackIndex"]);
  }

  return (
    <div className="px-2 pt-0 pb-2 bg-gray-200 dark:bg-gray-900 md:px-4 md:pb-4">
      <div className="sticky top-0 h-2 mx-auto bg-gray-200 md:h-4 dark:bg-gray-900 max-w-screen-md"></div>
      <div className="p-4 mx-auto bg-white dark:bg-gray-800 max-w-screen-md">
        <Header
          examResultYear={examResultYear(new Date())}
        />

        <TrackChoice
          testsValues={testsValues}
          currentTrackIndex={getTrackIndex()}
          updateTrackIndex={updateTrackIndex}
        />

        <OverallResults
          trackTestsValues={testsValues["tracks"][getTrackIndex()]}
        />

        <TestsForm
          trackTestsValues={testsValues["tracks"][getTrackIndex()]}
          updateGrade={updateGrade}
          toggleHiddenOptions={toggleHiddenOptions}
          showHiddenOptions={testsValues["tracks"][getTrackIndex()]["showHiddenOptions"]}
        />

        <Options
          updateTheme={updateTheme}
          theme={themeValue["theme"]}
          setTestsValues={setTestsValues}
        />

        <Footer />
      </div>
    </div>
  );
}

export default App;
