import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Markup } from 'interweave';
import TestResultDiv from './TestResultDiv';
import Constants from '../../data/constants';

interface TestResult {
  grade: string;
  coefficient: number;
}

interface Test {
  testName: string;
  testResults: TestResult[];
  optional: boolean;
  toolTip: string;
}

interface Props {
  test: Test;
  testIndex: number;
  typeIndex: number;
  updateGrade: (event: React.ChangeEvent<HTMLInputElement>, typeIndex: number, testIndex: number, resultIndex: number) => void;
  showHiddenOptions: boolean;
}

function TestDiv(props: Props) {
  let { test, testIndex, typeIndex, updateGrade, showHiddenOptions } = props;
  const FIRST_INDEX = 0;
  const LAST_RESULT_INDEX = test.testResults.length - 1;
  const widthClasses = test.testResults.length === 2 ? { left: 'w-1/2 md:w-36', right: 'w-1/2 md:w-36' } : { left: 'w-2/3 md:w-48', right: 'w-1/3 md:w-24' }

  function isHiddeable(test: Test): boolean {
    return test.optional && test.testResults.every(x => x.grade === '' || x.grade === Constants.NOT_APPLICABLE)
  }

  return (
    <div>
      { (testIndex === FIRST_INDEX) &&
        <div className="flex items-center justify-between w-full">
          <div></div>
          <div className="flex w-full md:w-auto">
            <div className={`${widthClasses['left']} p-2 font-bold text-center text-yellow-600 bg-yellow-200 dark:bg-yellow-600 dark:text-gray-100`}>
              Première
            </div>
            <div className={`${widthClasses['right']} p-2 font-bold text-center text-green-600 bg-green-200 dark:bg-green-600 dark:text-gray-100`}>
              Terminale
            </div>
          </div>
        </div>
      }
      {(showHiddenOptions || !isHiddeable(test)) &&
        (<div className="flex flex-wrap items-center justify-between w-full md:flex-nowrap">
          <div>
            <span className="inline-block w-full pt-1 text-gray-800 md:font-bold dark:text-gray-400 dark:font-semibold md:pt-0">
              {test.testName}
              {test.toolTip && (
                <>
                  <Popup
                    trigger={open => (
                      <span className="ml-2 align-middle pb-0.5 inline-block icon-info-circle-solid"></span>
                    )}
                    position="top center"
                    on={['hover', 'focus']}
                    arrow={false}
                    closeOnDocumentClick
                  >
                    <div className="p-2 text-sm"><Markup content={test.toolTip}/></div>
                  </Popup>
                </>
                )
              }
            </span>
            <span className="hidden md:inline">
              <span className="mr-1">Coefficient:</span>
              {Number.isInteger(test.testResults.reduce((x, y) => x + y.coefficient, 0)) ?
                test.testResults.reduce((x, y) => x + y.coefficient, 0) :
                test.testResults.reduce((x, y) => x + y.coefficient, 0).toFixed(Constants.DECIMALS_NUMBER)}
            </span>
          </div>
          <div className="flex w-full md:w-auto">
            {test.testResults.map((result, resultIndex) => {
              return (
                <TestResultDiv
                  typeIndex={typeIndex}
                  testIndex={testIndex}
                  resultIndex={resultIndex}
                  isLastResultIndex={LAST_RESULT_INDEX === resultIndex}
                  isHiddeable={isHiddeable(test)}
                  numberOfResults={test.testResults.length}
                  resultGrade={result.grade}
                  updateGrade={updateGrade}
                  key={test.testName+resultIndex}
                />
              )
            })}
          </div>
        </div>)
      }
    </div>
  )
}

export default TestDiv;
