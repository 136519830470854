import OverallResultDiv from './OverallResultDiv';
import Constants from '../data/constants';
import { merits } from '../data/merits';
import { isMeritForAverage, maximumGradesNumber, gradesNumber, coefficientsTotal, gradesTotal } from '../lib/computation';
import { overallAverage } from '../lib/utils';

interface Merit {
  minGrade: number;
  maxGrade: number;
  name: string;
}

interface TrackName {
  complete: string
  shortened: string
}

interface TestResult {
  grade: string;
  coefficient: number;
}

interface Test {
  testName: string;
  testResults: TestResult[];
  optional: boolean;
}

interface TestsType {
  testsTypeName: string
  tests: Test[]
}

interface TrackTestsValues {
  trackName: TrackName;
  testsTypes: TestsType[]
}

interface Props {
  trackTestsValues: TrackTestsValues;
}

function OverallResults(props: Props) {
  let { trackTestsValues } = props;
  let overallAverageValue: number | '-' = overallAverage({ coefficientsTotal: coefficientsTotal(trackTestsValues) , gradesTotal: gradesTotal(trackTestsValues) });
  let merit: Merit | undefined = merits.find(isMeritForAverage(overallAverageValue));
  let results = [
    {
      value: `${gradesNumber(trackTestsValues)}/${maximumGradesNumber(trackTestsValues)}`,
      label: "Nombre de notes saisies"
    },
    {
      value: [overallAverageValue, <span key="key" className="hidden md:inline">/{Constants.GRADE_MAXIMUM}</span>],
      label: "Votre moyenne"
    },
    {
      value: merit ? merit['name'] : '-',
      label: "Votre mention"
    }
  ]

  return (
    <div className="sticky py-2 mt-6 bg-white grid grid-cols-3 gap-2 top-12 md:top-14 dark:bg-gray-800">
      {results.map((result, resultIndex) => {
        return (
          <OverallResultDiv
            resultValue={result.value}
            resultLabel={result.label}
            key={result.label}
          />
        )
      })}
    </div>
  )
}

export default OverallResults;
