import Constants from '../data/constants';

export function makeInputGradeOrEmptyString(input: number | string): (number | '') {
  input = String(input).replace(',','.');
  input = parseFloat(input);
  if (Number.isFinite(input)
        && input <= Constants.GRADE_MAXIMUM
        && input >= Constants.GRADE_MINIMUM)
  {
    return input;
  }
  else {
    return '';
  }
}

interface Grades {
  coefficientsTotal: number
  gradesTotal: number
}

export function overallAverage(grades: Grades): (number | '-') {
  if (grades.coefficientsTotal !== 0) {
    let result:number = grades.gradesTotal/grades.coefficientsTotal;
    if (!Number.isInteger(result)) {
      // to avoid grades like 11.00
      let isResultEndingWith00: boolean = Number((result*100).toFixed(0))%100 === 0
      const numberOfDecimals = isResultEndingWith00 ? 0 : Constants.DECIMALS_NUMBER
      result = Number(result.toFixed(numberOfDecimals));
    }

    return result;
  }
  else {
    return '-';
  }
}

export function examResultYear(date: Date): string {
  const SEPTEMBER_MONTH_NUMBER = 8;
  const currentYear = date.getFullYear();
  const currentMonthNumber = date.getMonth();

  if (currentMonthNumber >= SEPTEMBER_MONTH_NUMBER) {
    return String(currentYear+1);
  }
  else {
    return String(currentYear);
  }
}

export function isThemeDark(theme: string): boolean {
  return theme === Constants.DARK_THEME
}

export function fileName(trackName:string = 'Générale'): string {
  return `2019_depliant_1re_${trackName.toLowerCase().replace(/é/g,'e')}_reussirbac.pdf`
}
