function Footer() {
  return (
    <div className="mt-12 text-center">
      <p>
        <a href="https://www.20aubac.fr" target="_blank" rel="noreferrer" className="text-lg">
          Corrigés de français et de philo <span className="hidden md:inline"> - 20aubac</span> <span className="icon-external-link"></span>
        </a>
      </p>
      <p className="text-sm">
        Source des coefficients et épreuves: Ministère de l'Éducation Nationale (<a href="https://www.education.gouv.fr/reussir-au-lycee/comment-calculer-votre-note-au-baccalaureat-325511" target="_blank" rel="noreferrer">Modalités <span className="icon-external-link"></span></a>, <a href="https://www.education.gouv.fr/bo/21/Hebdo30/MENE2121270N.htm" target="_blank" rel="noreferrer">BO 28/07/2021 <span className="icon-external-link"></span></a>).<br />
        Les données ne sont conservées que localement. Aucun tracking n'est effectué (<a href="https://gitlab.com/mdegoys/simulateur_bac_react" target="_blank" rel="noreferrer">Code source <span className="icon-external-link"></span></a>).

      </p>
    </div>
  )
}

export default Footer;
